@media print {
   .no-print{
        display:none;
    }
}


@for $i from 1 through 20 {
    .mw-#{$i * 10} {
        max-width: 10px * $i !important;
    }
}

@for $i from 1 through 20 {
    .h-#{$i * 10} {
        height: 10px * $i !important;
    }
}

@for $i from 1 through 20 {
    .w-#{$i * 10} {
        width: 10px * $i !important;
    }
}

.align-middle{
    vertical-align: middle;
}

.tbl-btn{
    margin: 0px 5px;
}

a.text-dark{
    color: $dark-light !important;
}

.color_bright{
    color: $bright !important; 
}

.text-nowrap{
    white-space: nowrap;
}

.align-middle{
    vertical-align: middle !important;
}

.no-padding{
    padding: 0px;
}

.full_width{
    width: 100% !important;
}

td.table-field{
    margin: 0px;
    padding: 0px;

    * {
        margin: 0px;    
    }
    
    .md-errors-spacer{
    	min-height: 0px;
    }
    
}

.big-dialog{
    width: 90%; 
    max-width: 90% !important;  
    height: 90%;
}

.inline-field{
    margin: 0px; 
    .md-errors-spacer{
    	min-height: 0px;
    }       
}